import store from "@/store/index";
import { clearLocalStorage } from "@/assets/js/storage.js";

/**获取标准格式的UUID*/
export function getUUid() {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

/**判断对象是否已经存在于数组中了*/
export function isRepeatObjInArr(arr, obj) {
  return JSON.stringify(arr).indexOf(JSON.stringify(obj)) > -1;
}

/**消息提示*/
export function alertMessage(type, text) {
  ElMessage({
    type: type,
    showClose: true,
    duration: 2000,
    message: text,
  });
}

/**下载文件并且修改名称*/
export function downloadFile(url, filename) {
  const x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  x.onload = function () {
    const url = window.URL.createObjectURL(x.response);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };
  x.send();
}

/*axios下载并且修改名称 用此方法改js文件需引入axios
也可通过设置content-disposition来修改名称 文档：
https://help.aliyun.com/document_detail/64052.html
*/
export function downToAxios(url, fileName) {
  axios({
    url: url,
    method: "get",
    responseType: "blob",
    params: {
      fileName: fileName,
    },
  })
    .then((res) => {
      if (!res.data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // 释放URL对象所占资源
      window.URL.revokeObjectURL(url);
      // 用完即删
      document.body.removeChild(link);
    })
    .catch((res) => {});
}

/**转换时间为YYYY-MM-DD hh:mm:ss 格式*/
export function timeFormat(time) {
  let timestamp = new Date(time).getTime();
  let date = new Date(timestamp);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

/**计算两个时间相差天数*/
export function dateDiffDay(start_time, end_time) {
  let timestampStart = start_time
    ? new Date(start_time).getTime()
    : new Date().getTime();
  let timestampEnd = end_time
    ? new Date(end_time).getTime()
    : new Date().getTime();
  let startTime = 0,
    endTime = 0;
  if (timestampStart < timestampEnd) {
    startTime = timestampStart;
    endTime = timestampEnd;
  } else {
    startTime = timestampEnd;
    endTime = timestampStart;
  }
  let timeDiff = endTime - startTime;
  let day = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return day;
}

/**清理用户缓存信息*/
export function clearUserInfo() {
  //清空登录信息
  clearLocalStorage("sid");
  //清除下载消息
  clearLocalStorage("download");
  //清空用户信息
  store.commit("setUserInfo", {});
  //清空到期天数
  store.state.expirationDays = 9999;
}

/**表单数据为空验证*/
//2023-05-17日之前的validateRequired写在每个vue里面 不修改
export function validateRequired(value, callback, error) {
  if (typeof value !== "string") value = "";
  if (value.trim() === "") {
    callback(error);
  } else {
    callback();
  }
}

/**文本每隔几位自动补一个符号*/
export function updateTextFormat(text, count, mark) {
  let value = text.trim();
  //value = value.replace(new RegExp(mark, 'g'), '');
  //每两位添加一个-
  let markText = `(.{${count}})`;
  value = value.replace(new RegExp(markText, "g"), `$1${mark}`);
  let last = value.charAt(value.length - 1);
  //去除最后一位
  if (last === mark) value = value.slice(0, -1);
  // value = value.replace(/`${mark}${mark}`/g, mark);
  return value;
}

/**所有输入框全部失焦*/
export function blurAllInput(className) {
  let formDom = document.querySelector(className);
  if (!formDom) return;
  let inputEles = formDom.querySelectorAll("input");
  if (!inputEles || !inputEles.length) return;
  for (let i = 0; i < inputEles.length; i++) {
    inputEles[i].blur();
  }
}

//是否有效的json字符串
export function isJSON(str) {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return obj;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}
