import axios from "axios";
import store from "../store/index";
import { getLocalStorage } from "../assets/js/storage.js";
import { getUUid, alertMessage, clearUserInfo } from "../assets/js/tool.js";

axios.defaults.timeout = 10000;
axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";
//let localURL = "http://test.jfueling.com/authorization-web/";
let localURL = "http://39.108.85.37/authorization-web/";
let serverURL = window.location.origin + "/authorization-web/";
axios.defaults.baseURL =
  process.env.NODE_ENV == "development" ? localURL : serverURL;

axios.defaults.transformRequest = [
  function (data) {
    let ret = new FormData();
    for (let it in data) {
      ret.append(it, data[it]);
    }
    return ret;
  },
];

// http请求拦截器
axios.interceptors.request.use(
  async (config) => {
    try {
      config.headers.sid = getLocalStorage("sid") || getUUid();
      config.headers.lang = getLocalStorage("lang") || "en";
      return config;
    } catch (e) {
      return await Promise.reject(e);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// http响应拦截器
axios.interceptors.response.use(
  async (response) => {
    try {
      let status = response.data.status;
      //登录失效
      if (status === -102) {
        //清空用户缓存信息
        clearUserInfo();
        //跳转回首页
        let href = window.location.href;
        let path = href.split("#")[1];
        let homeUrl =
          process.env.NODE_ENV === "development"
            ? "#/"
            : window.location.origin + window.location.pathname + "#/";
        //规定页码跳转回首页
        if (store.state.logoutPage.indexOf(path) > -1)
          window.location.href = homeUrl;
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

function _axios(url, params, method = "post") {
  let sendData =
    method === "post"
      ? params
      : {
          params: params,
        };
  return axios[method](url, sendData)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      let lang = getLocalStorage("lang") || "en";
      let text = lang === "en" ? "Failed to request data！" : "请求失败！";
      let errText = e ? e.msg || text : text;
      alertMessage("error", errText);
    });
}

export function get(url, params) {
  return _axios(url, params, "get");
}

export function post(url, data) {
  return _axios(url, data, "post");
}

export default axios;
